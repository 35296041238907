import { Avatar, Separator, Flex } from '@chakra-ui/react';
import { GroupedNotificationData } from '@shared/zod-schemas';
import NotificationContent from './NotificationContent';
import InvitationContent from './InvitationContent';

export default function NotificationItem({
	notification,
	isLast,
}: {
	notification: GroupedNotificationData;
	isLast: boolean;
}) {
	return (
		<>
			<Flex key={notification.id} gap={3} px={4} py={3} rounded="md">
				<Avatar.Root>
					<Avatar.Image src={notification.image || ''} />
					<Avatar.Fallback>{notification.noImageText || ''}</Avatar.Fallback>
				</Avatar.Root>
				<Flex fontSize="14px" flexDirection="column" gap={1} flex={1}>
					{notification.type === 'notification' && <NotificationContent notification={notification} />}
					{notification.type === 'invitation' && <InvitationContent notification={notification} />}
				</Flex>
			</Flex>
			{!isLast && <Separator />}
		</>
	);
}
