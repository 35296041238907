import { useMemo, useEffect, useState } from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { useQueryClient } from '@tanstack/react-query';
import { FiBell } from 'react-icons/fi';
import {
	getGetGroupedNotificationsQueryKey,
	useGetGroupedNotifications,
	useMarkAllGroupedNotificationsRead,
} from '@shared/client-api';
import { GroupedNotification } from '@shared/zod-schemas/';
import { errorToast, successToast } from '@/utils/toast';
import { createClient } from '@/supabase/client';
import { mergeNotificationsAndInvitations } from '@/utils/notification';
import { useUser } from '@/supabase/AuthContext';

import {
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverRoot,
	PopoverTitle,
	PopoverTrigger,
} from '@/components/ui/popover';
import Notifications from './Notifications';
import { Button } from '../ui/button';

export function NotificationPopover() {
	const n = useTranslations('Notifications');
	const [notifications, setNotifications] = useState<GroupedNotification>();

	const queryClient = useQueryClient();
	const user = useUser();

	const rqMarkAllNotificaltionsRead = useMarkAllGroupedNotificationsRead();
	const onMarkAllNotificationsRead = async () => {
		await rqMarkAllNotificaltionsRead
			.mutateAsync(
				{
					data: { newer: [], older: [] },
				},
				{
					onSuccess: () => {
						successToast(n('markedAsRead'));
					},
					onError: () => {
						errorToast(n('markedAsReadFailed'));
					},
				},
			)
			.then(() => queryClient.invalidateQueries({ queryKey: getGetGroupedNotificationsQueryKey() }));
	};

	useEffect(() => {
		const supabase = createClient();
		// nikde jsem moc nedohledal nejaky error stavy. nevim jeslti to treba reconnectuje samo apod.
		// musi se to vyzkouset jak se to bude chovat pripadne dodelat nejaky auto reconnect
		const changes = supabase
			.channel('table-db-changes')
			.on(
				'postgres_changes',
				{
					event: 'INSERT',
					schema: 'public',
					table: 'Notification',
				},
				(payload) => {
					// todo tohle je na refactor trochu te logiky
					// z toho realtime se nedotahne info u useru tak ho tady takhle docpu
					setNotifications((oldState) => ({
						older: oldState?.older || [],
						newer: [
							...mergeNotificationsAndInvitations([{ ...payload.new, user }], []),
							...(oldState?.newer || []),
						],
					}));
				},
			)
			.on(
				'postgres_changes',
				{
					event: 'INSERT',
					schema: 'public',
					table: 'Invitation',
				},
				() => {
					// todo tady taky chybej data, potreba to refactorovat aby byli k dispozici
					// setNotifications((oldState) => ({
					// 	older: oldState?.older || [],
					// 	newer: [...mergeNotificationsAndInvitations([], [payload.new]), ...(oldState?.newer || [])],
					// }));
					queryClient.invalidateQueries({ queryKey: getGetGroupedNotificationsQueryKey() });
				},
			)
			.subscribe();
		return () => {
			supabase.removeChannel(changes);
		};
	}, [user, queryClient]);

	const notificationsResponse = useGetGroupedNotifications();

	useEffect(() => {
		if (notificationsResponse?.data) {
			// obcas mne prijde ze tam zustanou vyset ty notifikace z realtime i po reset queries
			// ale mam pocit ze je to jen dev modem, chce to pak testnout nasazeny
			setNotifications(notificationsResponse?.data);
		}
	}, [notificationsResponse?.data]);

	const displayNotifications = useMemo(
		() => (notifications?.older?.length ?? 0) + (notifications?.newer?.length ?? 0) > 0,
		[notifications],
	);

	return (
		<PopoverRoot>
			<PopoverTrigger asChild>
				<Box position="relative">
					<Box aria-label="open menu" mr={2} cursor="pointer">
						<FiBell size="18px" />
					</Box>
					{displayNotifications && (
						<Flex
							alignItems="center"
							cursor={displayNotifications ? 'pointer' : 'default'}
							justifyContent="center"
							position="absolute"
							bg="fg.redText"
							borderRadius="50%"
							w={4.5}
							h={4.5}
							color="white"
							top={-2.5}
							left={2}
						>
							<Text fontSize="xs" textStyle="xs">
								{(notifications?.older?.length ?? 0) + (notifications?.newer?.length ?? 0)}
							</Text>
						</Flex>
					)}
				</Box>
			</PopoverTrigger>
			<PopoverContent width="100%" maxW={471} minW={{ base: 324, md: 471 }}>
				<PopoverArrow />
				<PopoverBody gap="3" display="grid" w="100%" p={0} pb={3} maxH="564px" overflowY="auto">
					<PopoverTitle
						display="flex"
						alignContent="space-between"
						px={5}
						py={4}
						borderBottom="1px solid"
						borderColor="border"
						justifyContent="space-between"
						alignItems="center"
					>
						<Heading color="fg.normalContrast" fontWeight={600} fontSize="xl">
							{n('title')}
						</Heading>

						<Button
							h="30px"
							variant="plain"
							color="fg.mediumContrast"
							p={0}
							loading={rqMarkAllNotificaltionsRead.isPending}
							disabled={notifications?.newer?.length === 0 && notifications?.older?.length === 0}
							_hover={{
								textDecoration: 'underline',
							}}
							onClick={onMarkAllNotificationsRead}
						>
							{n('markAllAsRead')}
						</Button>
					</PopoverTitle>

					{notifications && <Notifications groupedNotifications={notifications} />}

					{!((notifications?.older?.length ?? 0) + (notifications?.newer?.length ?? 0)) && (
						<Box w="100%" py={1} px={5}>
							<Text textAlign="left" fontSize="md">
								{n('notFound')}
							</Text>
						</Box>
					)}
				</PopoverBody>
			</PopoverContent>
		</PopoverRoot>
	);
}
