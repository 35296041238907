import { forwardRef } from 'react';
import { Tooltip as ChakraTooltip, Portal } from '@chakra-ui/react';

export interface TooltipProps extends ChakraTooltip.RootProps {
	showArrow?: boolean;
	portalled?: boolean;
	portalRef?: React.RefObject<HTMLElement>;
	content: React.ReactNode;
	contentProps?: ChakraTooltip.ContentProps;
	disabled?: boolean;
}

// eslint-disable-next-line react/display-name
export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>((props, ref) => {
	const { showArrow, children, disabled, portalled, content, contentProps, portalRef, ...rest } = props;

	if (disabled) return children;

	return (
		<ChakraTooltip.Root {...rest} openDelay={100}>
			<ChakraTooltip.Trigger asChild>{children}</ChakraTooltip.Trigger>
			<Portal disabled={!portalled} container={portalRef}>
				<ChakraTooltip.Positioner>
					<ChakraTooltip.Content ref={ref} {...contentProps}>
						{showArrow && (
							<ChakraTooltip.Arrow>
								<ChakraTooltip.ArrowTip />
							</ChakraTooltip.Arrow>
						)}
						{content}
					</ChakraTooltip.Content>
				</ChakraTooltip.Positioner>
			</Portal>
		</ChakraTooltip.Root>
	);
});
