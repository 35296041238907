import { format } from 'date-fns';

export const formatDate = (date: Date | undefined | null) =>
	date ? new Date(date.toString()).toLocaleDateString() : '';

export const formatDateTime = (date: Date | undefined | null) =>
	date ? format(new Date(date), 'dd.MM.yyyy HH:mm') : '';

export const formatTime = (date: Date | undefined | null) => (date ? format(date, 'HH:mm') : '');

export const formatTimeForInputValue = (date: Date | null | undefined) => {
	if (!date) return undefined;

	// Převod na lokální čas pomocí toLocaleTimeString s nastavením časového formátu
	const timeString = date.toLocaleTimeString('cs-CZ', {
		hour12: false,
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
	});

	return timeString;
};
