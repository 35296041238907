import { Flex, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { useQueryClient } from '@tanstack/react-query';
import { GroupedNotificationData } from '@shared/zod-schemas';
import { getGetGroupedNotificationsQueryKey, useUpdateInvitationById } from '@shared/client-api';
import { useRouter } from '@/i18n/routing';
import { errorToast, successToast } from '@/utils/toast';
import { Button } from '@/components/ui/button';
import { formatDateTime } from '../form/utils';

export default function InvitationContent({ notification }: { notification: GroupedNotificationData }) {
	const n = useTranslations('Notifications');
	const r = useTranslations('InvitationTable');

	const router = useRouter();

	const queryClient = useQueryClient();
	const { mutateAsync, isPending } = useUpdateInvitationById();

	return (
		<>
			<Text fontWeight={700}>
				{notification.senderName}{' '}
				<Text as="span" fontWeight={400}>
					{n('youWereInvited')}
					<br />
				</Text>{' '}
				{notification.receiverName}
			</Text>

			<Text color="fg.mediumContrast">{notification.createdAt && formatDateTime(notification.createdAt)}</Text>

			<Flex mt={2} gap={3}>
				<Button
					h={8}
					colorPalette="red"
					size="sm"
					variant="subtle"
					onClick={() => {
						mutateAsync(
							{
								id: notification.id,
								data: {
									state: 'rejected',
								},
							},
							{
								onSuccess: () => {
									successToast(r('acceptedInvite'));
								},
								onError: () => {
									errorToast(r('rejectedInvite'));
								},
							},
						).then(() => {
							queryClient.resetQueries({ queryKey: getGetGroupedNotificationsQueryKey() });
							router.refresh();
						});
					}}
					loading={isPending}
				>
					{r('rejectedButton')}
				</Button>
				<Button
					h={8}
					colorPalette="green"
					size="sm"
					onClick={() => {
						mutateAsync(
							{
								id: notification.id,
								data: {
									state: 'approved',
								},
							},
							{
								onSuccess: () => {
									successToast(r('editMessage'));
								},
								onError: () => {
									errorToast(r('error'));
								},
							},
						).then(() => {
							router.refresh();
							queryClient.resetQueries({ queryKey: getGetGroupedNotificationsQueryKey() });
						});
					}}
					loading={isPending}
				>
					{r('approvedButton')}
				</Button>
			</Flex>
		</>
	);
}
