import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { NestedSearch } from '@shared/zod-schemas';

import NestedResult from '@/components/command/search/NestedResult';

export default function NestedCategory({ ns, onClose }: { ns: NestedSearch; onClose: () => void }) {
	const t = useTranslations('Command');

	return (
		<Box pl={4}>
			<Text fontSize="12px" fontWeight={500} mb={2}>
				{t(`${ns.category}.${ns.category}`)}
			</Text>
			<Flex flexDir="column" gap={1}>
				{ns.results.map((nr) => (
					<NestedResult key={nr.id} nr={nr} categoryName={ns.category} onClose={onClose} />
				))}
			</Flex>
		</Box>
	);
}
