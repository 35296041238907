import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { useAtom, useAtomValue } from 'jotai';
import { NestedResult as NestedResultType } from '@shared/zod-schemas';
import { useRouter } from '@/i18n/routing';

import { highlightText } from '@/components/command/utils/highlightText';
import { resetSearchAtom, searchValueAtom } from '@/components/command/store';

export default function NestedResult({
	nr,
	categoryName,
	onClose,
}: {
	nr: NestedResultType;
	categoryName: string;
	onClose: () => void;
}) {
	const router = useRouter();
	const t = useTranslations('Command');

	const [, resetSearch] = useAtom(resetSearchAtom);
	const searchValue = useAtomValue(searchValueAtom);

	return (
		<Box
			onClick={() => {
				resetSearch();
				onClose();
				router.push(nr.href);
			}}
			key={nr.id}
			textStyle="lg"
			display="flex"
			flexDir="column"
			justifyContent="flex-start"
			w="100%"
			py={2}
			px={4}
			rounded="8px"
			transition="all 0.2s ease"
			cursor="pointer"
		>
			<Text fontSize="16px">{highlightText(nr.data.find((el) => el.isTitle)?.value, searchValue)}</Text>

			{nr.data
				.filter((el) => !el.isTitle && el.value)
				.map((el) => (
					<Text key={el.key} fontSize="12px">
						{t(`${categoryName}.${el.key}`)}: {highlightText(el.value, searchValue)}
					</Text>
				))}
		</Box>
	);
}
