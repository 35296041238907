'use client';

import { Box, Flex, Text, Badge } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { useQueryClient } from '@tanstack/react-query';
import { GroupedNotificationData } from '@shared/zod-schemas';
import { getGetGroupedNotificationsQueryKey, useUpdateNotificationById } from '@shared/client-api';
import { useRouter } from '@/i18n/routing';
import { errorToast, successToast } from '@/utils/toast';
import { getBadgeColor } from '@/utils/notification';
import { Button } from '../ui/button';
import { formatDateTime } from '../form/utils';
import { Tooltip } from '../ui/tooltip';

export default function NotificationContent({ notification }: { notification: GroupedNotificationData }) {
	const n = useTranslations('Notifications');
	const t = useTranslations('Notifications.types');
	const router = useRouter();

	const badgeColor = getBadgeColor(notification.notification?.type ?? '');

	const queryClient = useQueryClient();
	const { mutateAsync, isPending } = useUpdateNotificationById();

	return (
		<>
			<Flex justifyContent="space-between" gap={1}>
				<Text fontWeight={700}>
					{notification.senderName}{' '}
					<Text as="span" fontWeight={400}>
						{notification.notification?.infoContent}
						<br />
					</Text>{' '}
					{notification.notification?.content}
				</Text>

				<Tooltip content={n('markAsRead')} showArrow positioning={{ placement: 'top' }}>
					<Button
						variant="plain"
						w={6}
						h={6}
						px={2}
						py={3}
						onClick={() => {
							mutateAsync(
								{
									id: notification.id,
									data: {
										isRead: true,
									},
								},
								{
									onSuccess: () => {
										successToast(n('markAsRead'));
									},
									onError: () => {
										errorToast(n('markAsRead'));
									},
								},
							).then(() => {
								queryClient.resetQueries({ queryKey: getGetGroupedNotificationsQueryKey() });
								router.refresh();
							});
						}}
						loading={isPending}
					>
						<Box w="8px" height="8px" borderRadius="50%" backgroundColor="secondaryLight" />
					</Button>
				</Tooltip>
			</Flex>
			<Flex gap={2}>
				<Text color="fg.mediumContrast">
					{notification.createdAt && formatDateTime(notification.createdAt)}
				</Text>
				{badgeColor && <Badge colorPalette={badgeColor}>{t(notification.notification?.type)}</Badge>}
			</Flex>
		</>
	);
}
